.btn {
    box-shadow: 0 0 0.7em #8e8d8d;
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    margin-bottom: 10px;
}

table {
    font-family: 'Rajdhani', sans-serif;
    font-size: 1.1rem;
}

a {
    color: #187b2f;
}

a:hover {
    color: #0d4419;
    text-decoration: none;
}

.form-control:focus {
    border-color: #00c161;
    box-shadow: 0 0 0 0.2rem rgba(0, 193, 97, 0.25);
}

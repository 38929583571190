.img-loaded img {
    animation: fadeIn cubic-bezier(0.23, 1, 0.32, 1) 1;
    animation-delay: 0.1s;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;
}

.img-loading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.img-loading img {
    opacity: 0;
}

.img-loading div {
    box-sizing: border-box;
    display: inline-block;
    width: 27px;
    height: 27px;
    margin: auto;
    border: 3px solid #000;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
    border-color: #000 #000 #000 transparent;
}

@media only screen and (max-width: 370px) {
    .img-loading div {
        width: 15px;
        height: 15px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes preloaderAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.preloader {
    width: 50px;
    height: 50px;
    animation-name: preloaderAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.83, 0.01, 0.3, 0.99);
    animation-delay: -0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    margin: auto;
}

.preloaderSmall {
    width: 50px;
    height: 50px;
    animation-name: preloaderAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.83, 0.01, 0.3, 0.99);
    animation-delay: -0.3s;
    margin: auto;
}

.auth {
    background-image: linear-gradient(to bottom right, #9cd6c3, #30b58a);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    padding-top: 80px;
}

.smalljumbo {
    width: 400px;
}

.bigjumbo {
    width: 80%;
}

@media only screen and (max-width: 1100px) {
    .bigjumbo {
        width: 95%;
    }
}

.jumbotron {
    background-color: rgba(233, 236, 239, 0.7);
    box-shadow: 1px 1px 5px #00000094;
}

.jumbotron img {
    margin-bottom: 20px;
}

.jumbotron h3 {
    font-weight: 600;
    text-transform: uppercase;
}

.btm-links {
    font-size: 0.8rem;
    margin: 2px 0 0 0;
}

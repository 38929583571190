.memberlist {
    overflow-x: auto;
}

.memberlist tr {
    cursor: pointer;
}

@media only screen and (max-width: 1320px) {
    .memberlistTable {
        width: 1340px !important;
    }
}

.csvButton {
    margin-top: 10px;
}

.membercount p {
    margin-top: 1rem;
}
